/** @flow
 * StratoDem Analytics : index
 * Principal Author(s) : Michael Clawar
 * Secondary Author(s) :
 * Description :
 *
 *  (c) 2016- StratoDem Analytics, LLC
 *  All Rights Reserved
 */

import React from 'react';
import { Link } from 'gatsby';

import { Layout, SEO } from '../../components';
import { MultifamilySolution, SeniorHousingSolution } from '../../components/images';

import Col from 'react-bootstrap/Col';

import Row from 'react-bootstrap/Row';

import './solutions.scss';

const Solutions = () => (
  <Layout>
    <SEO
      title="Solutions"
      keywords={['real estate research', 'stratodem', 'analytics', 'portfolio analysis', 'demographics', 'senior housing', 'senior living', 'multifamily', 'homebuilders', 'retail']}
      description="StratoDem Analytics solutions in real estate - senior housing and multifamily"
    />
    <div className="solutions">
      <div className="sd-section solutions-hero">
        <h3>Explore StratoDem Analytics solutions</h3>
        <p>
          StratoDem Analytics helps investors, operators, developers and advisory firms across real
          estate to make investment, development, disposition and
          marketing decisions faster, earlier and better by building predictive models on massive
          local-level economic and demographic data.
        </p>
      </div>

      <div className="sd-section solutions-options">
        <Row>
          <Col md={6} className="center">
            <h3><Link to="/senior-housing">Senior housing &rarr;</Link></h3>
            <div><Link to="/senior-housing"><SeniorHousingSolution /></Link></div>
            <div><Link to="/senior-housing">
              <button className="btn-tertiary">Senior housing solutions</button>
            </Link></div>
          </Col>
          <Col md={6} className="center">
            <h3><Link to="/multifamily">Multifamily &rarr;</Link></h3>
            <div><Link to="/multifamily"><MultifamilySolution /></Link></div>
            <div><Link to="/multifamily">
              <button className="btn-tertiary">Multifamily solutions</button>
            </Link></div>
          </Col>
        </Row>
      </div>


      <div className="sd-section solutions-options">
        <Row>
          <Col md={6} className="center">
            <h3><Link to="/solutions">Single family &rarr;</Link></h3>
            <div>
              <button className="btn-tertiary disabled">Coming soon</button>
            </div>
          </Col>
          <Col md={6} className="center">
            <h3><Link to="/solutions">Retail &rarr;</Link></h3>
            <div>
              <button className="btn-tertiary disabled">Coming soon</button>
            </div>
          </Col>
        </Row>
        <div style={{clear: 'both'}} />
      </div>
    </div>
  </Layout>);

export default Solutions;
